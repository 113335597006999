<template>
  <div class="Template">
    <div class="content">
      <h2>预赛视频作品要求</h2>
      <div class="span"></div>
      <div class="boxcontent">
        <workTemplate></workTemplate>
      </div>
    </div>
    <footerCard class="iosFooter"></footerCard>
  </div>
</template>

<script>
import footerCard from "../../components/footerCard.vue";
import workTemplate from "../../components/workTemplate.vue";
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  components: {
    footerCard,
    workTemplate,
  },
};
</script>

<style scoped lang="scss">
.Template {
  padding: 80px 0px;
  background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: #f9f9f9;
  @media screen and (max-width: 520px) {
    background: none;
    background-color: #fff;
    padding: 0;
  }
  .content {
    width: 1200px;
    margin: 0px auto;
    @media screen and (max-width: 520px) {
      width: 100%;
    }
    h2 {
      font-size: 26px;
      text-align: center;
      color: #05258e;
      margin-bottom: 10px;
      @media screen and (max-width: 520px) {
        padding-top: 20px;
      }
    }
    .span {
      display: block;
      margin: 0px auto;
      width: 24px;
      height: 4px;
      background: #05258e;
      border-radius: 2px;
      margin-bottom: 38px;
      @media screen and (max-width: 520px) {
        margin-bottom: 18px;
      }
    }
    .boxcontent {
      width: 1269px;
      @media screen and (max-width: 520px) {
        width: 100%;
        height: 100%;
        padding: 0;
        margin-bottom: 50px;
      }
      margin-bottom: 150px;
    }
  }
}
</style>
